<template>
  <TModal
    title="Add tracking"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :creating="creating"
    @click-create="createTracking(tracking_code)"
  >
    <TInputText
      label="Tracking code"
      placeholder="Input tracking"
      :value.sync="tracking_code"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tracking_code: "",
      creating: false,
    };
  },
  methods: {
    createTracking(code) {
      this.creating = true;
      this.$store
        .dispatch(
          "order.trackings.create",
          this.lodash.pickBy({
            code: code,
          })
        )
        .then(() => {
          this.tracking_code = "";
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.creating = false;
        });
    },
  },
};
</script>
