<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.trackings"
      @click-clear-filter="clearFilter"
      resource="/management/agency"
      creatable
      deletable
      @click-create="showModalCreate = true"
    >
      <template #received="{ item }">
        <td>
          <CIcon
            :name="item.received ? 'cil-check-circle' : 'cil-x-circle'"
            :class="item.received ? 'text-success' : 'text-danger'"
            size="custom"
            width="17px"
          />
        </td>
      </template>
      <template #distributed="{ item }">
        <td>
          <CIcon
            :name="item.distributed ? 'cil-check-circle' : 'cil-x-circle'"
            :class="item.distributed ? 'text-success' : 'text-danger'"
            size="custom"
            width="17px"
          />
        </td>
      </template>
      <template #created_at="{ item }">
        <td>
          <TMessageDateTime :content="item.created_at" small />
        </td>
      </template>
      <template #reference="{ item }">
        <td>
          <TLink
            v-for="reference in item.tracking_references"
            :key="reference.tracking_id"
            :content="reference.reference_id"
            :to="getReferenceLink(reference)"
          />
        </td>
      </template>

      <template #code-filter>
        <TInputText
          placeholder="Tracking code"
          :value.sync="filter.code"
          @update:value="filterChange"
        />
      </template>
      <template #created_at-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setCreatedAtFilter"
        />
      </template>
    </TTableAdvance>
    <TrackingModal
      :show.sync="showModalCreate"
      @created="showModalCreate = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TrackingModal from "./components/TrackingModal.vue";

export default {
  components: { TrackingModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px; min-width: 40px" },
        {
          key: "id",
          label: "Id",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "code",
          label: "Tracking code",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "received",
          label: "Received at warehouse",
          _classes: "",
          _style: "width: 170px; min-width: 170px",
        },
        {
          key: "distributed",
          label: "Distributed",
          _classes: "",
          _style: "width: 130px; min-width: 130px",
        },
        {
          key: "reference",
          label: "Reference",
          _classes: "",
          _style: "width: 150px; min-width: 150px",
        },
        {
          key: "created_at",
          label: "Created at",
          _style: "width: 220px; min-width: 220px",
        },
      ],
      showModalCreate: false,
      dateRange: {
        start: null,
        end: null,
      },
      filter: {},
      filter_between: {},
    };
  },
  created() {
    this.$store.dispatch("order.trackings.fetch.if-first-time");
  },
  computed: {
    ...mapGetters({
      list: "order.trackings.list",
      detail: "order.trackings.detail",
      loading: "order.trackings.loading",
    }),
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch("order.trackings.apply-query", filterFields);
    },
    setCreatedAtFilter({ start, end }) {
      this.filter_between["created_at"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.filter_between = {};
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
    getReferenceLink(reference) {
      switch (reference.reference_type) {
        case "purchase":
          return `/purchase/purchases/${reference.reference_id}`;
        case "order":
          return `/sale/orders/shipment/${reference.reference_id}`;
      }
    },
  },
};
</script>
